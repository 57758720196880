import React from "react";
import { Box } from "@mui/material";
import {getShortDate} from "../../../../utils/formats";

export interface IVerificationDetailHistoryIdentityProps {
    verification: any;
}
export default function VerificationDetailHistoryIdentity(props: IVerificationDetailHistoryIdentityProps) {
    const {verification} = props;

    return (
        <Box>
            <Box className="history-title bold">Identity Verification</Box>
            <Box className="history-table">
                <Box className="history-row history-table-header">
                    <Box className="history-date-column bold">Date</Box>
                    <Box className="history-action-column bold">Action</Box>
                    <Box className="history-detail-column bold">Step/Detail</Box>
                </Box>
                {verification?.history &&
                    verification.history.map((el: any, index: number) => {
                        return (
                            <Box className="history-row" key={index}>
                                <Box className="history-date-column">{getShortDate(el.gen_created_at)}</Box>
                                <Box className="history-action-column">{el.short_name}</Box>
                                <Box className="history-detail-column">{el.detail}</Box>
                            </Box>
                        );
                    })
                }
            </Box>
        </Box>
    );
}
