import React from "react";
import { Box } from "@mui/material";
import VerificationDetailHistoryPayments from "./VerificationDetailHistoryPayments";
import VerificationDetailHistoryIdentity from "./VerificationDetailHistoryIdentity";

export interface IVerificationDetailHistoryProps {
    verification: any;
}
export default function VerificationDetailHistory(props: IVerificationDetailHistoryProps) {
    const {verification} = props;

    return (
        <Box className="section-box" height="100%">
            <Box className="section-title" style={{height: 32, display: "flex", alignItems: "flex-end"}}>
                History
            </Box>

            <Box className="section-divider"/>
            <VerificationDetailHistoryPayments verification={verification}/>
            <VerificationDetailHistoryIdentity verification={verification}/>
        </Box>
    );
}
