export type VerificationStatusColor = "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";

export type VerificationItem = {
  id: number;
  reservation_id: number;
  channel: string;
  check_in: string;
  check_in_tz: string | undefined;
  created_at: string;
  status: string;
  status_color: VerificationStatusColor;
  channel_id: number;
  isLock: boolean;
  timezone: string;
};

export type LockType = {lockedBy: string; success: boolean};

export type StepType = {
  id: number;
  verification_id: number;
  name: string;
  detail: string;
  provider_id: string;
  result: any;
  deleted: boolean;
  created_flow_id: string;
  updated_flow_id: string;
  created_at: Date;
  updated_at: Date;
};

export type UserType = {
  id: number;
  email: string;
  roles: string[];
  deleted: boolean;
  created_at: Date;
  updated_at: Date;
};

export type NotificationType = {
  id: number;
  verification_id: number;
  email_notification_id: number;
  to: string;
  user_id: number;
  user: UserType;
  deleted: boolean;
  created_flow_id: string;
  updated_flow_id: string;
  created_at: Date;
  updated_at: Date;
};

export type VerificationType = {
  id: number;
  type: string;
  client_id: string;
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
  data: any;
  status: string;
  status_detail: string;
  provider: string;
  deleted: boolean;
  created_flow_id: string;
  updated_flow_id: string;
  created_at: Date;
  updated_at: Date;
  expires_at: Date;
  client_requested_at: Date;
  client_notified_at: Date;
  notifications?: NotificationType[];
  steps?: StepType[];
  hidden?: boolean;
};

export namespace IDologyResponse {
  export type KeyMessage = {
    key: string;
    message: string;
  };

  export type Question = {
    prompt: string;
    type: string;
    answer: string[];
  };

  export type EmailRecord = {
    emailVerificationDate: string;
    emailOwner: string;
    domainCountry: string;
    fraudRisk: string;
    fraudRiskScore: number;
  };

  export type IDIQ = {
    error?: string[];
    failed?: string[];
    "id-number"?: number;
    "summary-result"?: KeyMessage;
    results?: KeyMessage;
    qualifiers?: KeyMessage[];
    questions?: Question[];
    "differentiator-question"?: Question;
    "velocity-results"?: KeyMessage[];
    idnotescore?: number;
    restriction?: KeyMessage;
    "answers-received"?: number;
    "idliveq-result"?: KeyMessage;
    "iq-summary-result"?: string;
    "idliveq-error"?: KeyMessage;
    "idliveq-challenge-result"?: KeyMessage;
    "iq-challenge-summary-result"?: string;
    "located-email-record"?: EmailRecord;
  };
}

export type ReservationType = {
  type: string;
  id: string;
  attributes: {
    channel?: string;
    vacasa_is_merchant_of_record?: boolean;
    reservation_id: number;
    listing_channel_reservation_id?: string;
    created_by: number;
    creation_date?: string; //date-time
    updated_at?: string; //date-time
    confirmation?: string; //date-time
    cancelled?: boolean;
    cancellation_date?: string;
    notes?: string;
    first_night?: string; //YYYY-MM-DD, in reality it's date-time
    last_night?: string; //YYYY-MM-DD, in reality it's date-time
    contract_needs_acknowledgement: boolean;
    e_lock_code?: string;
    total?: number;
    paid?: number;
    balance?: number;
    rent?: number;
    fee_amount?: number;
    tax_amount?: number;
    trip_protection?: number;
    trip_protection_fee?: number;
    addons_amount?: number;
    first_name?: string;
    last_name?: string;
    address_1?: string;
    address_2?: string;
    phone?: string;
    phone_2?: string;
    email?: string;
    preferred_language?: string;
    adults?: number;
    children?: number;
    pets?: number;
    unit_id?: number;
    unit_name?: string;
    unit_address?: {
      address_1: string;
      address_2: string;
      city: string;
      state: string;
      zip: string;
      country: {
        id: number;
        name?: string;
        code?: string;
      };
    };
    city: {
      CityID: number;
      LanguageID: number;
      RegionID: number;
      City?: string;
      StateID: number;
      State?: string;
      StateName?: string;
      CountryID: number;
      CountryName?: string;
      StartDate: string;
      EndDate?: string; //YYYY-MM-DD
      Display: number;
      Active?: number;
      CityURL?: string;
      CanonicalURL?: string;
      StateCanonicalURL: string;
      CountryCanonicalURL: string;
      RegionDesc?: string;
      StateInName: number;
      CityWebDesc?: string;
      CityBinderDesc?: string;
      CityOneLineDesc?: string;
      MetaDesc: string;
      ExtDesc?: string;
      Lat: number;
      Lng: number;
      Mgmt?: string;
      OfficeID?: number;
      ImageID?: number;
      altCityName?: string;
      ZipRegex?: string;
    };
    terms?: string; //date-time
    driving_directions?: string;
    wifi?: boolean;
    wifi_network?: string;
    wifi_password?: string;
    lockbox_code?: string;
    lockbox_code_backup?: string;
    lockbox_code_notes?: string;
    parking_total_cars?: number;
    garage_parking?: boolean;
    parking_notes?: string;
    alarm_code?: string;
    alarm_code_notes?: string;
    gate_code?: string;
    gate_code_notes?: string;
    vehicle?: string;
    late_checkout?: boolean;
    extended?: boolean;
    type?: number;
  };
};

export interface UnitAllowListInterface {
  id: number;
  name: string;
  data: any;
  deleted: boolean;
  created_by: string;
  updated_by: string;
  updated_at: string;
  created_at: string;
}

export interface GoodBehaviourAllowListInterface {
  id: number;
  email: string;
  name: string;
  deleted: boolean;
  created_by: string;
  updated_by: string;
  updated_at: string;
  created_at: string;
}

export interface PaymentsVerificationInterface {
  id: number;
  verification_id: number;
  reservation_id: string;
  status: string;
  session_id: string | null;
  provider: string | null;
  provider_transaction_id: string | null;
  provider_result: string;
  provider_response: {SCOR: string, OMNISCORE: string} | null;
  payment_id: string | null;
  created_at: string;
  updated_at: string;
}

export interface PaymentsVerificationHistoryInterface {
  created_at: string;
  provider_transaction_id: string;
  provider_result: {name: string, type: string};
  payment_id: string;
  score: string;
  omniscore: string;
}

export const ProviderStatusName = {
  A: {name: "APPROVE", type: "success"},
  D: {name: "DECLINE", type: "error"},
  R: {name: "REVIEW", type: "warning"},
  P: {name: "PENDING", type: "warning"},
  E: {name: "ESCALATE", type: "warning"},
  S: {name: "SKIP", type: "success"},
}
